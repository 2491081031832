.add-household-container {
    padding-left: 25%;
    display: flex;
    height: 100vh;
    width: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .add-household-content {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .add-household-title {
    text-align: left;
    color: #8b0043;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  
  .add-household-selectors {
    padding-left: 110px;
    display: flex;
    gap:0px; 
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .block-section,
  .stair-section {
    flex: 0 0 50%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .label-with-line {
    position: relative;
    font-weight: bold;
    margin-bottom: 10px;
    width: 40%;
  }
  
  .label-with-line .line {
    height: 2px;
    background-color: #8b0043;
    width: 100%;
    margin-top: 10px;
  }
  
  .input-select {
    margin-top: 10px;
    width: 100%; 
    max-width: 200px; 
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  
  .apartment-inputs-table {
    margin-left: 15%;
    margin-top: 30px; 
  }
  
  .input-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .input-header {
    margin-left: 38px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
    text-align: center;
  }
  
  .input-field.small-input {
    flex: 1;
    max-width: 150px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .add-line-button {
    position: absolute;
    right: 200px;
    background-color: #ffca28;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 10px;
  }
  
  .add-line-button:hover {
    background-color: #e6b022;
  }
  
  .apartment-inputs-table .input-row:first-child .add-line-button {
    right: -40px;
  }

  .submit-section {
    margin-top: 20px;
    text-align: center;
  }
  
  .submit-button {
    background-color: #8b0043;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #6a002f;
  }
  


  