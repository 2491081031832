
.add-blocks-container-unique {
  margin-top: 80px;
  width: 100%;
  max-width: 900px;
  margin-left: 30%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  gap: 10px; 
}


.add-blocks-left-unique,
.add-blocks-right-unique {
  flex: 1;
  padding: 20px;
  margin-right: 10px; 
  margin-bottom: 10px; 
}


.add-blocks-title-unique,
.add-stairs-title-unique {
  color: #8E244D;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}


.block-name-input-unique,
.stair-name-input-unique,
.block-select-unique {
  width: 25%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f0f8ff;
  font-size: 16px;
  margin-bottom: 10px;
}


.add-block-button-unique,
.add-stair-button-unique {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #FFB74D;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-block-button-unique:hover,
.add-stair-button-unique:hover {
  background-color: #FF9800;
}


.block-select-unique {
  width: 62%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f0f8ff;
  font-size: 16px;
  margin-bottom: 10px;
}

.add-blocks-list-container {
  width: 900px;
  margin-left: 30%;
  margin-top: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-blocks-list {
  margin-top: 10px;
}

.block-details {
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  border-radius: 4px;
}

/* Modificări pentru ecrane mici */
@media screen and (max-width: 768px) {
  .add-blocks-container-unique {
    margin-top: 2px;
    width: 100%;
    height: 400px;
    max-width: 320px;
    margin-left: 2%;
    display: grid;
    justify-content: grid;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    gap: 0px; /* Reducerea distanței dintre coloane */
  }
  

  .add-blocks-left-unique,
  .add-blocks-right-unique {
    width: 100%;
    margin-bottom: 20px;
  }

  .add-blocks-title-unique,
  .add-stairs-title-unique {
    font-size: 22px;
  }

  .block-name-input-unique,
  .stair-name-input-unique,
  .block-select-unique {
    width: 70%;
    font-size: 16px;
  }

  .block-select-unique {
    width: 75%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f0f8ff;
    font-size: 16px;
    margin-bottom: 10px;
  }
  

  .add-block-button-unique,
  .add-stair-button-unique {
    width: 50%;
  }
  .block-select-unique {
    width: 62%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f0f8ff;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .add-blocks-list-container {
    width: 320px;
    margin-left: 2%;
    margin-top: 0px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-blocks-list {
    margin-top: 10px;
  }
  
  .block-details {
    padding: 10px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  


}
