body {
  font-family: 'Inter', sans-serif;
}

.create-association-container {
  margin-left: 250px;
  display: flex;
  height: 100vh;
  transition: margin-left 0.3s ease; 
}

.create-association-content {
  flex: 1;
  padding: 40px;
  background-color: #fff;
}

.create-association-form {
  max-width: 1100px;
  margin: 0 auto;
}

.create-association-form-title {
  position: relative;
  top: -5px;
  left: -20px;
  color: #8E244D;
  text-align: left;
  margin-bottom: 50px;
  margin-top: 10px;
  margin-left: 20px;
}

.create-association-section-title {
  color: #8E244D;
  margin-bottom: 10px;
}

.create-association-form-row {
  margin-top: 15px;
  display: flex;
  gap:200px;
  margin-bottom: 20px;
}



.create-association-checkboxes-row {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap; 
  width: 100%; 
}


.create-association-checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px; 
  font-size: 16px;
  color: #333;
  margin-left: 0; 
}


.create-association-checkbox-input {
  width: 18px; 
  height: 18px;
  cursor: pointer;
}


.create-association-input-label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}
.create-association-input-field {
  width: 130%; 
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f0f8ff;
}



.create-association-input-field {
  width: 150%; /* Lățimea inputurilor */
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f0f8ff;
}


.create-association-input-field:hover {
  border-color: #1f4d91;
  background-color: #f0f8ff;
  transition: border-color 0.3s, background-color 0.3s;
}

.create-association-input-field:focus {
  border-color: #275DAD;
  box-shadow: 0 0 5px rgba(39, 93, 173, 0.5);
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.create-association-form-submit {
  margin-top: 40px;
  margin-left: 540px;
}

.create-association-submit-button {
  width: 100%;  
  width: 150px;
  padding: 10px 20px;
  background-color: #275DAD;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-association-submit-button:hover {
  background-color: #1f4d91;
}


@media screen and (max-width: 768px) { 
  .sidebar {
    left: -250px;
  }

  .sidebar.open {
    left: 0;
  }

  .hamburger {
    display: block;
  }

  .menu {
    padding-left: 0;
  }

  .menu li {
    margin: 10px 0;
  }

  .menu li a {
    font-size: 16px;
  }

  .create-association-form {
    max-width: 100%;
  }

  .create-association-form-row {
    margin-top: 15px;
    display: flex;
    gap:35px;
    margin-bottom: 0px;
  }

  .create-association-checkboxes-row {
    flex-wrap: wrap; 
  }

  .create-association-checkbox-input {
    width: 18px;
    height: 18px;
  }

  .create-association-form-title {
    font-size: 22px;
  }

  .create-association-section-title {
    font-size: 18px;
  }

  .create-association-container {
    margin-left: 0; 
    padding-left: 15px;
  }

  .create-association-form-row {
    gap: 10px; 
    flex-direction: column; 
  }

  .create-association-input-field {
    width: 85%; 
  }

 
  .create-association-form-title {
    font-size: 22px; 
  }

  .create-association-section-title {
    font-size: 18px; 
  }
  .create-association-form-submit {
    margin-top: 40px;
    margin-left: 90px;
  }
  
}
