/* Stiluri de bază pentru sidebar */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #275dad;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar.closed {
  transform: translateX(-250px) !important;
}

.logo {
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
}

.logo-img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu ul {
  list-style: none;
  padding-left: 25px;
  margin: 0;
}

.menu li {
  margin: 15px 0;
}

.menu-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  transition: background-color 0.3s;
  border-radius: 0 25px 25px 0;
}

/* Separate hover and active states */
.menu-link:hover:not(.active) {
  background-color: rgba(59, 130, 246, 0.7); /* Slightly transparent blue only when not active */
}

.menu-link.active {
  background-color: #3b82f6; /* Solid blue for active state */
  font-weight: 500;
}

.menu-icon {
  margin-right: 10px;
  font-size: 18px;
  color: orange;
}

.divider-line {
  border: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 20px;
}

/* Stiluri pentru butonul hamburger - REDUCED SIZE */
.hamburger {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #fff;
  border: none;
  color: #333;
  font-size: 16px; /* Reduced from 20px */
  cursor: pointer;
  z-index: 1001;
  padding: 4px; /* Reduced from 6px */
  width: 30px; /* Added fixed width */
  height: 30px; /* Added fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  opacity: 1;
  pointer-events: all;
}

.hamburger.visible {
  opacity: 1;
  pointer-events: all;
  background-color: #f0f0f0;
}

.hamburger:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

/* Responsive: La ecrane mici sidebar-ul va fi închis inițial */
@media screen and (max-width: 990px) {
  .sidebar {
    transform: translateX(-250px);
    box-shadow: none;
  }
  .sidebar.open {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  /* Show hamburger only on mobile */
  .hamburger {
    display: flex;
  }
}

@media screen and (min-width: 991px) {
  /* Hide hamburger on desktop */
  .hamburger {
    display: none;
  }
}

